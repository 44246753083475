import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputSection from '../../components/InputSection'

const stdInputProps = {
  type: 'number',
  size: 'small',
  variant: 'outlined',
}

const DebtCountdown = () => {
  const [startDate, setDate] = React.useState(new Date().getTime())
  const [dateNow, setDateNow] = React.useState(new Date().getTime())
  const [originalTimeLeft, setOriginalTimeLeft] = React.useState(0)
  const [debt, setDebt] = React.useState(12000)
  const [interest, setInterest] = React.useState(3)
  const [totalDebt, setTotalDebt] = React.useState(3)
  const [monthlyPayment, setMonthlyPayment] = React.useState(1500)
  const [monthlyPaymentText, setMonthlyPaymentText] = React.useState(1000)

  const timeSinceStart = (dateNow - startDate) / 1000

  React.useEffect(() => {
    setInterval(() => {
      setDateNow(new Date().getTime())
    }, 100)
  }, [])

  const daysPerMonth = 365 / 12

  const timeLeft =
    (totalDebt / monthlyPayment) * 86400 * daysPerMonth - timeSinceStart
  const completedPart = timeLeft / (timeLeft + timeSinceStart)

  const initialCalculations = () => {
    let debtLeft = debt
    let month = 0
    let paid = 0

    while (debtLeft > 0 && month < 10000) {
      debtLeft += debtLeft * (interest / 1200)

      if (debtLeft < monthlyPayment) {
        paid += debtLeft
      } else {
        paid += monthlyPayment
      }

      month += 1
      debtLeft -= monthlyPayment
    }
    if (totalDebt !== paid) {
      setTotalDebt(paid)
    }
    return paid
  }

  React.useEffect(() => {
    initialCalculations()
  }, [totalDebt, debt, monthlyPayment, interest])

  if (originalTimeLeft === 0) {
    setOriginalTimeLeft(timeLeft)
  }

  function numberWithCommas(x) {
    if (isNaN(x)) {
      return 0
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  function fillZeros(x) {
    if (isNaN(x)) {
      return 0
    }
    if (('' + x).length === 1) {
      return '0' + x
    }
    return x
  }

  const yearsLeft = Math.floor(timeLeft / (86400 * 365))
  const daysLeft = Math.floor(timeLeft / 86400) % 365
  const hoursLeft = Math.floor((timeLeft % 86400) / 3600)
  const minutesLeft = Math.floor((timeLeft % 3600) / 60)
  const secondsLeft = Math.floor(timeLeft % 60)

  const useStyles = makeStyles((theme) => ({
    number: {
      fontSize: '2.5em',
      margin: '0',
    },
    text: {
      fontSize: '1.25em',
      marginLeft: '0.625rem',
      marginRight: '1.25rem',
    },
  }))

  const classes = useStyles()
  return (
    <Layout>
      <header className="App-header">
        <Helmet>
          <title>Debt payoff calculator</title>
          <meta name="description" content="Create a countdown for debt." />
        </Helmet>
        <Header
          links={['/', '/calculator/debt/']}
          texts={['Calculators', 'Debt calculator']}
        />
        <h1>Debt payoff countdown</h1>
        <div
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '700px',
            marginBottom: '50px',
            textAlign: 'left',
          }}
        >
          <p>
            Calculate how long it takes to pay off debt and the total amount
            paid including interest. Paying off high interest debt is a good
            step to a better financial health.
          </p>
        </div>
        <div
          style={{
            display: 'flex', //flex
            flexDirection: 'row',
            maxWidth: '400px',
            paddingTop: '15px',
            boxShadow: '0 2px 8px rgba(0,0,0,.2)',
            background: 'white',
            width: 'inherit',
            justifyContent: 'center',
            marginBottom: '92px',
          }}
        >
          <div
            style={{
              flexFlow: 'wrap',
              justifyContent: 'center',
              paddingBottom: '20px',
              maxWidth: '340px',
              display: 'inherit',
              paddingLeft: '48px',
              paddingRight: '48px',
            }}
          >
            <h2
              style={{
                marginBottom: '1.2rem',
                width: '100%',
              }}
            >
              Your debt data
            </h2>
            <InputSection>
              <p style={{ marginTop: '7px' }}>Debt</p>
              <TextField
                {...stdInputProps}
                label=""
                onChange={(e) => setDebt(parseInt(e.target.value))}
                value={debt}
              />
            </InputSection>
            <InputSection>
              <p style={{ marginTop: '7px' }}>Monthly payment</p>
              <TextField
                {...stdInputProps}
                label=""
                onChange={(e) => {
                  const value = parseInt(e.target.value)
                  setMonthlyPayment(value)
                  if (value !== 0) {
                    setMonthlyPayment(value)
                    setMonthlyPaymentText(value)
                  } else {
                    setMonthlyPaymentText(value)
                  }
                  const timeLeft =
                    (debt / monthlyPayment) * 86400 * daysPerMonth -
                    timeSinceStart
                  setOriginalTimeLeft(timeLeft)
                }}
                value={monthlyPayment}
              />
            </InputSection>
            <InputSection>
              <p style={{ marginTop: '7px' }}>Yearly interest (%)</p>
              <TextField
                {...stdInputProps}
                label=""
                onChange={(e) => {
                  setInterest(e.target.value)
                }}
                value={interest}
              />
            </InputSection>
          </div>
        </div>

        <div
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '1200px',
            marginBottom: '10px',
            textAlign: 'center',
          }}
        >
          {(yearsLeft ? yearsLeft : '') && (
            <React.Fragment>
              <span className={classes.number}>
                {yearsLeft ? yearsLeft : ''}
              </span>
              <span className={classes.text}>Years</span>
            </React.Fragment>
          )}
          <span className={classes.number}>{daysLeft}</span>
          <span className={classes.text}>days</span>
          <span className={classes.number}>{fillZeros(hoursLeft)}</span>
          <span className={classes.text}>hours </span>

          <span className={classes.number}>{fillZeros(minutesLeft)}</span>
          <span className={classes.text}>minutes </span>
          <span className={classes.number}>{fillZeros(secondsLeft)}</span>
          <span className={classes.text}>seconds</span>
          <br />
        </div>
        <div
          style={{
            marginTop: '20px',
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '900px',
            marginBottom: '50px',
            textAlign: 'center',
          }}
        >
          <span className={classes.number}>
            ${numberWithCommas((completedPart * totalDebt).toFixed(2))}
          </span>
          <span className={classes.text}>remaining including interest</span>
        </div>
      </header>
      <span style={{ paddingBottom: '32px' }} />
    </Layout>
  )
}

export default DebtCountdown
